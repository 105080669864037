
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        








.tags {
  list-style: none;
  color: $tango;

  li {
    @include brown;
    @include fluid(
      font-size,
      (
        mob: 9px,
        xl: 11px,
      )
    );
    font-weight: 700;
    display: inline-block;
    text-transform: uppercase;
    margin-right: 5px;
  }
}
