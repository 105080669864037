
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        





.wysiwyg::v-deep {
  width: 100%;

  & > {
    h1,
    h2,
    h3,
    .lead {
      // prettier-ignore
      @include fluid(margin-bottom, (xxs: 30px, xl: 60px));
    }

    p {
      // prettier-ignore
      @include fluid(margin-bottom, (xxs: 20px, xl: 40px));
    }

    blockquote {
      // prettier-ignore
      @include fluid(margin, (xxs: 60px, xl: 120px));
    }

    a {
      color: $tango;
      text-decoration: underline;
    }

    ul {
      // prettier-ignore
      @include fluid(margin-bottom, (xxs: 30px, xl: 60px));

      margin-top: 0;

      li + li {
        margin-bottom: 5px;
      }
    }

    .gallery,
    .player {
      // prettier-ignore
      @include fluid(margin, (xxs: 60px, xl: 120px));
    }

    @include mq(s) {
      .gallery,
      .player {
        margin-left: -140px;
      }
    }
  }
}
