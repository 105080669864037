
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        









































@import '~flickity/dist/flickity.css';

.card {
  position: relative;
}

@include mq($until: s) {
  .card {
    display: none;
  }

  .updates__container {
    .card:nth-child(1),
    .card:nth-child(2),
    .card:nth-child(3) {
      display: flex; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.card + .card {
  @include fluid(
    margin-top,
    (
      mob: 30px,
      m: 50px,
    )
  );
}

.updates__container::after {
  content: '';
  display: none;
}

.updates__nav {
  display: none;
}

@include mq(s) {
  .updates {
    position: relative;
  }

  .card {
    width: 35%;
    max-width: 350px;
    margin: 0 20px;
    margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */
  }

  .updates__all {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: flex-end;

    .link {
      margin-right: -20px;
    }
  }

  .updates__container {
    position: relative;
    max-width: none;
    padding: 0;
    padding-left: 200px;

    @include mq(xl) {
      padding-left: calc(50vw - 460px);
    }

    &::after {
      content: 'flickity';
      display: none;
    }
  }

  .updates__nav {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    pointer-events: none;

    button {
      pointer-events: all;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      // padding-right: 0;
    }
  }

  .updates__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 80px;
    height: 80px;
    padding: 0;
    background: transparent;
    border: 1px solid $alto;
    border-radius: 50%;
    // background: $white;
    cursor: pointer;

    svg {
      fill: $black;
    }

    &:hover {
      &.prev {
        svg {
          animation: arrow 0.3s $ease-in-quad reverse;
        }
      }

      &.next {
        svg {
          animation: arrow 0.3s $ease-in-quad;
        }
      }
    }
  }

  @keyframes arrow {
    50% {
      transform: translateX(210%);
    }

    50.1% {
      transform: translateX(-210%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .updates__btn + .updates__btn {
    margin-top: 10px;
  }
}
