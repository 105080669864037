
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        










































@import '~flickity/dist/flickity.css';

.gallery,
[class*='gallery--'] {
  ::v-deep .flickity-viewport {
    position: relative;
    width: 100%;
    height: 0 !important; /* stylelint-disable-line declaration-no-important */
    padding-bottom: 56.25%;
  }

  @include mq(l) {
    &[class*='--split'] {
      display: flex;

      ::v-deep .flickity-viewport {
        padding-bottom: 100%;
      }
    }
  }
}

.gallery__carousel {
  position: relative;
  display: flex;
  flex-direction: column-reverse;

  [class*='gallery--'][class*='--split'] & {
    margin-bottom: 3rem;
  }

  @include mq(l) {
    [class*='gallery--'][class*='--split'] & {
      flex-shrink: 0;
      width: inside-col(9);
      margin-right: inside-col(2);
      margin-bottom: 0;
    }
  }
}

.gallery__cell {
  @include get-all-space;

  img {
    @include image-fit;
  }
}

.gallery__pagination {
  position: relative;
  margin-bottom: 5px;
  text-align: center;
}

.gallery__num {
  @include brown;

  padding: 10px 0;
  font-size: 15px;

  i {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 14px;
    height: 1px;
    margin: 0 3px;
    vertical-align: middle;
    background: $black;
  }
}

.gallery__num__current {
  color: $tango;
}

.gallery__nav {
  position: absolute;
  top: 50%;
  right: 0;
  display: flex;
  align-items: center;
  transform: translateY(-50%);
}

.gallery__btn {
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.gallery__btn + .gallery__btn {
  margin-left: 15px;
}

@include mq(s) {
  .gallery__pagination {
    position: absolute;
    z-index: 10;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 20px 15px;
    background: $white;
  }

  .gallery__nav {
    position: static;
    top: auto;
    transform: none;
    margin-left: 25px;
  }

  .gallery__num {
    padding: 0;

    span {
      display: inline-block;
      min-width: 20px;
    }
  }

  .gallery__legend {
    width: 80%;
    margin-top: 30px;
  }

  .gallery__content {
    @include mq(l) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .gallery__content__title {
    margin-bottom: 2rem;

    @include mq(l) {
      margin-bottom: 5rem;
    }
  }
}
