
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        












































.card {
  position: relative;
  display: flex;
}

.card__image {
  position: relative;
  flex-basis: 120px;
  overflow: hidden;
  height: 0;
  padding-bottom: 120px;

  .picture {
    @include image-fit;

    transform: scale(1.1);
    transition: transform 0.3s;

    .card:hover & {
      transform: scale(1);
    }
  }

  .exclu {
    position: absolute;
    z-index: 2;
    top: 1rem;
    right: 1rem;
    width: 5rem;
  }
}

.card__content {
  @include fluid(
    padding-left,
    (
      mob: 20px,
      m: 40px,
    )
  );

  display: flex;
  flex-direction: column;
  flex: 1;

  .tags {
    margin-top: auto;
  }
}

.card__subtitle {
  font-size: 1.5rem;
}

.card__link {
  @include get-all-space;
}

@mixin big-card {
  display: block;

  .card__content {
    display: block;
    padding-left: 0;
    // padding-right: 20px;
  }

  .card__image {
    width: 100%;
    margin-bottom: 15px;
    padding-bottom: 100%;
  }

  .card__date {
    margin-bottom: 7px;
    color: $silver-chalice;
    font-size: 13px;
  }

  .card__title {
    width: 70%;
    // margin-bottom: 35px;
  }
}

.card--big {
  @include big-card;
}

@include mq(s) {
  .card {
    @include big-card;
  }
}
