
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        




































@import '~plyr/dist/plyr.css';

.player__block {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.player__cover {
  @include get-all-space;
  z-index: 25;

  img {
    @include image-fit;
  }
}

.player__btn {
  position: absolute;
  z-index: 25;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 12px 15px;
  background: $white;
  border: 0;

  p {
    font-size: 15px;
    margin-bottom: 0;
  }

  svg {
    margin-left: 8px;
    fill: $tango;
  }
}

.player::v-deep .plyr {
  @include get-all-space;

  z-index: 20;
}

.player__link {
  @include get-all-space;

  z-index: 30;
}

@include mq($until: s) {
  .player__title {
    @include brown;

    font-size: 20px;
    font-weight: 400;
  }
}

@include mq(s) {
  .player {
    position: relative;
  }

  .player__content {
    position: absolute;
    z-index: 5;
    bottom: 0;
    left: 0;
    width: 85%;
    padding: 30px 40px;
    color: $white;

    @include mq(l) {
      width: 75%;
      padding: 40px 60px;
    }
  }

  .player__gradient {
    @include get-all-space;

    z-index: 4;
    background: linear-gradient(
      to top,
      rgba($black, 0.8) 0%,
      rgba($black, 0) 80%,
      rgba($black, 0) 100%
    );
  }
}
